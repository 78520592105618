import {drawLayer} from "../layers/Draw";
import {Fill, Stroke, Style} from "ol/style";
import {DEFAULT_FEATURE_STROKE_COLOR, FEATURE_STROKE_WIDTH} from "../constants";
import {toggleSaveButton} from "../state/transitions";
import {fillFeature, undoFeature} from "../features";
import {ON_CLEAR_DRAWING, ON_FILL_DRAWING} from "../event-constants";

const selectStyle = new Style({
    fill: new Fill({
        color: DEFAULT_FEATURE_STROKE_COLOR,
    }),
    stroke: new Stroke({
        color: DEFAULT_FEATURE_STROKE_COLOR,
        width: FEATURE_STROKE_WIDTH,
    }),
});

let featureId = null;

/**
 * initialize feature popup
 */
export function initializeFeaturePopup(){
    // fill event handlers
    let fillEvent = $('#fill-event');
    fillEvent.click(handleFillFeature);
    fillEvent.click(hideFeaturePopup);

    // undo event handler
    $('#undo-event').click(handleUndoEventClicked);
    $('#undo-event').click(hideFeaturePopup);
}

/**
 * Mark a feature as filled
 */
function handleFillFeature() {
    fillFeature(featureId);

    drawLayer.getSource().getFeatureById(featureId).setStyle(selectStyle);

    $(document).trigger($.Event(ON_FILL_DRAWING));
}

/**
 * Delete selected drawing from screen
 */
function handleUndoEventClicked(){
    drawLayer.getSource().removeFeature(drawLayer.getSource().getFeatureById(featureId));

    undoFeature(featureId);

    // check if the save button should be hidden or not
    toggleSaveButton();

    $(document).trigger($.Event(ON_CLEAR_DRAWING));
}

/**
 * Show feature popup at current mouse location
 * @param e mouse pointer event
 */
export function renderFeaturePopup(selectedFeatureId, x, y, isFilled = false){
    // get last drawn events pixel location or whatever
    featureId = selectedFeatureId;

    const popup = $('#fill-undo-popup');

    popup.removeClass('hidden');
    popup.css({"top": `${y}px`, "left": `${x}px`})

    $('#fill-event').prop('disabled', isFilled);
}

/**
 * Hide feature popup
 */
export function hideFeaturePopup(){
    const popup = $('#fill-undo-popup');
    // popup
    popup.addClass('hidden');

    featureId = null;
}