import {handleResponse} from "./HandleResponse";
import {getURL} from "./UrlBuilder";
import {
    ON_LAYER_LOADED,
    ON_LAYER_LOADING,
    ON_LAYER_LOADING_ERROR,
} from "../event-constants";
import {createPopupOptions, renderErrorMessage} from "../control/InfoPopup";
import {checkServerStatus} from "./TimeoutHandler";

export function callAPI(endpoint, body = null, params = []){

    $(document).trigger(ON_LAYER_LOADING);

    let requestObject = getURL(endpoint, params, body);

    return fetch(requestObject)
        .then((response) =>
            handleResponse(response)
                .then((data) => {
                    $(document).trigger(ON_LAYER_LOADED);
                    return Promise.resolve(data);
                })
                .catch((err) => {
                    return Promise.reject(err)
                })
        )
        .catch((err) => {
            $(document).trigger(ON_LAYER_LOADING_ERROR);

            if ([502, 504].includes(err.status)) {
                checkServerStatus();

            } else {
                const data = err.text && JSON.parse(err.text);
                const error = (data && data.message) || err.statusText;
                renderErrorMessage(createPopupOptions({text: error}));
            }

            return Promise.reject(err)
        });
}