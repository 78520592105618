import {eventDisplayLayer} from "../layers/EventDisplay";
import {wmsTileLayer} from "../layers/EventTileWMS";
import {drawPolygonFromTiles} from "../features";
import {callAPI} from "../model/Request";
import {YEAR_CHANGED} from "../event-constants";
import {EVENT_NOT_FOUND_MESSAGE, FOCUSED_FEATURE} from "../constants";
import {map} from "../Map";
import {renderErrorMessage} from "./InfoPopup";

export function handleSelectEvent(e){
    // get selected event from database
    callAPI('get-event-by-id', null, [e.detail.selection.value.id])
        .then((event) => {
            // set the year of the event
            let year = new Date(e.detail.selection.value.date).getFullYear();
            $(document).trigger($.Event(YEAR_CHANGED, {year: year}));

            // render the event on the map
            drawPolygonFromTiles(event);

            // set extent of map to show just the event:
            let feature = eventDisplayLayer.getSource().getFeatureById(event.id);
            let extent = feature.getGeometry().getExtent();
            map.getView().fit(extent, FOCUSED_FEATURE);
        })
        .catch((err) => {
            renderErrorMessage({text: EVENT_NOT_FOUND_MESSAGE});
        })
}

// if enter has been pressed, close the modal and apply search
export function handleSearchInputKeyDown(e){
    if(e.key === 'Enter'){
        refreshData();
    }
}

function refreshData() {
    eventDisplayLayer.getSource().clear();
    eventDisplayLayer.getSource().refresh();
    wmsTileLayer.refresh();
}