import customStyles from "../../css/VerticalSlider.css?raw";

const styleSheet = new CSSStyleSheet();
styleSheet.replaceSync(customStyles);

class VerticalSlider extends HTMLElement{
    static observedAttributes = ['step', 'ticks', 'class', 'min', 'max', 'value'];

    constructor() {
        super();
        const shadow = this.attachShadow({mode: 'open'});
        shadow.adoptedStyleSheets = [styleSheet];
        this.wrapper = document.createElement('div');
        this.slider = document.createElement('md-slider');
        this.slider.setAttribute('id', 'slider');
        this.slider.addEventListener('input', this._updateValue.bind(this));
        this.wrapper.appendChild(this.slider);
        shadow.appendChild(this.wrapper);
    }

    connectedCallback() {
        this.updateAttributes();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this.slider.setAttribute(name, newValue);
    }

    updateAttributes() {
        Array.from(this.attributes).forEach(attr => {
            this.slider.setAttribute(attr.name, attr.value);
        });
    }

    _updateValue(){
        this.setAttribute('value', this.slider.value);
        this.dispatchEvent(new CustomEvent('change', {detail: this.slider.value}));
    }
}

customElements.define('vertical-slider', VerticalSlider);