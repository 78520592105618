import Handlebars from "handlebars";
import {ON_CANCEL_INSERT_NEW_EVENT, ON_DISCARD_CHANGES, ON_SAVE_ATTRIBUTES_PRESSED} from "../event-constants";
import {createNewEditor} from "../Editor";

let modalID;
let cancelEvent;
export let editor;

// render the input form
export function prepareEventAttributesForm(templateObject = null){
    if(templateObject !== null){
        modalID = templateObject.modalID;

        if(templateObject.isEdit){
            cancelEvent = ON_DISCARD_CHANGES;
        }else{
            cancelEvent = ON_CANCEL_INSERT_NEW_EVENT;
        }

        let template = Handlebars.compile("{{>edit-event-form}}")
        let htmlContent = template(templateObject);
        $('#event-form-template').html(htmlContent);

        initializeEditor(templateObject.description);

        $('#close-event-sidebar').click(() => $(document).trigger(cancelEvent));
        $('#cancel-event-attributes').click(() => $(document).trigger(cancelEvent));
        $('#save-event-attributes').click(() => $(document).trigger(ON_SAVE_ATTRIBUTES_PRESSED));
        $('#create-event-color').on('close-menu', handleChangeColor);
    }

    renderEventAttributesModal();
}

// initialize editor with data
async function initializeEditor(eventDescription){
    if(editor != null){
        destroyEditor();
    }

    setEditor(await createNewEditor("#editor")
        .then((newEditor) => {
            if(eventDescription != null){
                newEditor.setData(eventDescription);
            }else{
                newEditor.setData('');
            }

            return newEditor;
        })
        .catch((err) => console.log(err)));
}

// show the modal to the user
function renderEventAttributesModal(){
    $(`#${modalID}`).removeClass('hidden');
}

// hide the modal from the user
export function hideEventAttributesModal(){
    $(`#${modalID}`).addClass('hidden');
}

// handle syncing the selected color and the color display
function handleChangeColor(){
    let colorCode = $('#create-event-color').val();
    $('#selected-color').css('background-color', colorCode);
}

function setEditor(newEditor){
    editor = newEditor
}

function destroyEditor(){
    editor.destroy();
}