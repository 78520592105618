export function handleResponse(response){
    return response.text()
        .then((text) => {
            if(!response.ok){
                if ([401, 403].includes(response.status)) {
                    // TODO auto logout if 401 or 403 response returned from api
                    // authService.logout();
                    // window.location.reload(true);
                }

                response.text = text;
                return Promise.reject(response);
            }

            const data = text && JSON.parse(text);
            return Promise.resolve(data);
        })
}