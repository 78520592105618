import {ON_EDIT_TILES_PRESSED} from "../event-constants";
import {map} from "../Map";
import {eventDisplayLayer} from "../layers/EventDisplay";
import {FOCUSED_FEATURE} from "../constants";

let eventID;

export function initializeRecenterEvent(){
    // add event listeners
    // $(document).on(ON_MAP_CLICK_EVENT, handleEventSelected);
    $(document).on(ON_EDIT_TILES_PRESSED, showButtonOnEditTiles);
    $('#recenter-event').click(centerOnFeature);
}

function handleEventSelected(e){
    eventID = e.feature.getId();
}

function showButtonOnEditTiles(){
    $('#recenter-event').removeClass('hidden')
}

function centerOnFeature(){
    let selectedFeature = eventDisplayLayer.getSource().getFeatureById(eventID);
    let extent = selectedFeature.getGeometry().getExtent();
    map.getView().fit(extent, FOCUSED_FEATURE);
}

export function handleHideRecenterButton(){
    $('#recenter-event').addClass('hidden');
}