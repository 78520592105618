import {
    brushSizeLevels,
    TOP_LEFT_TILE,
    EPSG_3857_RATIO,
    maxZoom,
    tileGrid,
} from "./layers/Common";
import {eventLayer} from "./layers/Event"
import {drawLayer} from "./layers/Draw";
import {eventDisplayLayer} from "./layers/EventDisplay";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import {MultiPolygon} from "ol/geom";
import {Fill, Style} from "ol/style";
import {
    NEW_FEATURE_FILL_COLOR,
    DELETE_FEATURE_FILL_COLOR,
    DEFAULT_FEATURE_STROKE_COLOR, 
    FEATURE_STROKE_WIDTH,
    FILL_MODE
} from "./constants";
import {handleTriggerEditMode, removeInteractions} from "./control/UserMode";
import {map} from "./Map";
import {FLAG_PAINT, ON_DRAW_END} from "./event-constants";
import {Draw} from "ol/interaction";
import {hideFeaturePopup, renderFeaturePopup} from "./control/FeaturePopup";
import {getBrushSize} from "./control/BrushSize";
import {wmsTileLayer} from "./layers/EventTileWMS";

// list of all drawn on tiles
let tiles = {
    freeDraw: {}, // object containing all selected tiles
    features: {} // object containing features and their tiles + metadata
};

// tiles of current drawing
let currentDrawingTiles = {};

let draw = null;
let previousTile = null;
let firstCoordinate = null;

// clear all events from map
const clearEvents = () => {
    eventLayer.getSource().clear();
    drawLayer.getSource().clear();
    resetTiles();
}

function drawPolygonFromTiles(event, existingFeature = null) {
    let tiles = event.tiles
    let eventPolygon = new MultiPolygon([]);
    let tilesObject = {};
    for (let tile of tiles) {
        let [z, x, y] = tile.split('-').map(Number);
        let polygon = getPolygonFromXYZ(z, x, y)
        eventPolygon.appendPolygon(polygon.getGeometry());
        tilesObject[tile] = {z: z, x: x, y: y};
    }

    let eventObject = {...event};
    eventObject.tilesObject = tilesObject;
    eventPolygon.setProperties(eventObject);
    let feature = new Feature(eventPolygon.transform('EPSG:4326', 'EPSG:3857'));
    feature.setId(event.id);

    feature.setStyle(new Style({
        fill: new Fill({
            color: event.color_hex
        })
    }))

    feature.enableEditMode = enableEditMode;

    if(existingFeature){
        eventDisplayLayer.getSource().removeFeature(existingFeature);
    }

    eventDisplayLayer.getSource().addFeature(feature)
}

function getPolygonFromXYZ(z, x, y, color){
    let geometry = TOP_LEFT_TILE.clone();
    geometry.translate((EPSG_3857_RATIO.x * x), -(EPSG_3857_RATIO.y * y));

    let feature = new Feature({geometry});
    feature.getGeometry().setProperties({color});
    return feature;
}

// event highlighting functions
// Recursive function to create polygons for the lowest level of tiles
function createPolygons(z, x, y, color=NEW_FEATURE_FILL_COLOR) {
    let polygon = getPolygonFromXYZ(z, x, y, color);

    eventLayer.getSource().addFeature(polygon);
}

function isImageAtCoordinate(coordinate){
    // convert coordinates to pixel
    let pixel = map.getPixelFromCoordinate(coordinate);
    // check if there is an image underneath the tile
    let data = wmsTileLayer.getData(pixel);
    return data && data[3] > 0;
}

function markTilesForSelection(coordinates){
    for(let coordinate of coordinates){
        let [z, x, y] = tileGrid.getTileCoordForCoordAndZ(coordinate, maxZoom - 1);
        // check if the tile is marked for deletion, if it is, unmark it
        let isInsideImage = isImageAtCoordinate(coordinate);
        if(!isInsideImage && !(`${z+1}-${x}-${y}` in tiles.freeDraw)){
            tiles.freeDraw[`${z+1}-${x}-${y}`] = {z: z+1, x: x, y: y, new: true};
        }else if(isInsideImage && `${z+1}-${x}-${y}` in tiles.freeDraw && tiles.freeDraw[`${z+1}-${x}-${y}`].delete){
            delete tiles.freeDraw[`${z+1}-${x}-${y}`];
        }
    }
}

function markTilesForDeletion(coordinates){
    for(let coordinate of coordinates){
        let [z, x, y] = tileGrid.getTileCoordForCoordAndZ(coordinate, maxZoom - 1);
        // check if tile is marked for selection, if it is, unmark it
        if(`${z+1}-${x}-${y}` in tiles.freeDraw && tiles.freeDraw[`${z+1}-${x}-${y}`].new) {
            delete tiles.freeDraw[`${z+1}-${x}-${y}`];
        }
        // check if tile is in a non transparent image in wms layer, if it is, mark it for deletion
        if(isImageAtCoordinate(coordinate)){
            tiles.freeDraw[`${z+1}-${x}-${y}`] = {z: z+1, x: x, y: y, delete: true};
        }
    }
}

function displayTiles(){
    eventLayer.getSource().clear();
    for(let [tile, status] of Object.entries(tiles.freeDraw)){
        let [z, x, y] = tile.split('-').map(Number);
        let color = NEW_FEATURE_FILL_COLOR;
        if(status.delete){
            color = DELETE_FEATURE_FILL_COLOR;
        }
        createPolygons(z, x, y, color);
    }
}

// general function to mark if the tile should be selected or deleted
function handleTile(e) {
    // New drawing logic
    // instead of working with tile numbers directly, we will work with the coordinates of the tiles
    // we know the dimensions of each tile, so we can calculate the coordinate of each tile in the selected brush size (and through those numbers we can calculate the tile numbers)
    let coordinates = getCoordinatesForBrushSize(e.originalEvent.coordinate[0], e.originalEvent.coordinate[1], getBrushSize());// if we are selecting tiles

    // mark tiles for deletion or selection based on mouse button pressed (left click or right click)
    let pointerButton = e.originalEvent.originalEvent.buttons;

    if (pointerButton === 1) {
        markTilesForSelection(coordinates);
    } else if (pointerButton === 2) {
        markTilesForDeletion(coordinates);
    }

    // update the displayed tiles on the map
    displayTiles();
}

// function to get each coordinate of the tiles to be manipulated
function getCoordinatesForBrushSize(x, y, brushSize){
    const offsets = brushSizeLevels[brushSize];
    let coordinates = [];

    let deltaX;
    let deltaY;
    for(const [offsetX, offsetY] of offsets){
        deltaX = offsetX * EPSG_3857_RATIO.x;
        deltaY = offsetY * EPSG_3857_RATIO.y;

        coordinates.push([x+deltaX, y-deltaY]);
    }

    return coordinates;
}

// when the user is clicking on the map, handle selecting the tile
function handleTileClick(e){
    handleTile(e);
}

// when the user is dragging the mouse across the map, handle selecting the tiles
function handleTileDrag(e){
    if(e.originalEvent.dragging) {
        handleTile(e);
    }
}

// Bresenham's line algorithm implementation
function plotLineLow(xMin, yMin, xMax, yMax){
    let dx = xMax - xMin;
    let dy = yMax - yMin;
    let yi = 1

    if(dy < 0){
        yi = -1;
        dy = -dy;
    }

    let D = (2 * dy) - dx;
    let y = yMin;

    let coordinates = {};

    for(let i=xMin; i<=xMax; i++){
        let coordinate = [i, y];
        if(!(`${coordinate[0]}${coordinate[1]}` in coordinates)){
            coordinates[`${coordinate[0]}${coordinate[1]}`] = coordinate;
        }

        if(D > 0){
            y = y + yi;
            D = D + (2*(dy - dx));
        }else{
            D = D + 2*dy;
        }
    }

    return Object.values(coordinates).map(b => [4, b[0], b[1]]);
}

// Bresenham's line algorithm implementation
function plotLineHigh(xMin, yMin, xMax, yMax){
    let dx = xMax - xMin;
    let dy = yMax - yMin;
    let xi = 1;

    if(dx < 0){
        xi = -1;
        dx = -dx;
    }

    let D = (2 * dx) - dy;
    let x = xMin;

    let coordinates = {};

    for(let i=yMin; i<=yMax; i++){
        let coordinate = [x, i];
        if(!(`${coordinate[0]}${coordinate[1]}` in coordinates)){
            coordinates[`${coordinate[0]}${coordinate[1]}`] = coordinate;
        }

        if(D > 0){
            x = x + xi;
            D = D + (2*(dx - dy));
        }else{
            D = D + 2*dx;
        }
    }

    return Object.values(coordinates).map(b => [4, b[0], b[1]]);
}

// Bresenham's line algorithm implementation
function plotLine(xMin, yMin, xMax, yMax){
    if (Math.abs(yMax - yMin) < Math.abs(xMax - xMin)) {
        if (xMin > xMax) {
            return plotLineLow(xMax, yMax, xMin, yMin);
        } else {
            return plotLineLow(xMin, yMin, xMax, yMax);
        }
    } else {
        if (yMin > yMax) {
            return plotLineHigh(xMax, yMax, xMin, yMin);
        } else {
            return plotLineHigh(xMin, yMin, xMax, yMax);
        }
    }
}

// general function to reset the tiles object
function resetTiles(){
    tiles = {
        freeDraw: {},
        features: {}
    };
}

// start the drawing interaction
export function handlePaintLine(){
    removeInteractions();

    if(map.get(FLAG_PAINT) === 'false' || map.get(FLAG_PAINT) === undefined){
        addInteraction();
        map.set(FLAG_PAINT, 'true', true);
        $('#paint-line').prop('selected', true);
    }
}

// set up drawing interaction on the map, and all the event listeners needed when drawing
function addInteraction(){
    draw = new Draw({
        source: drawLayer.getSource(),
        type: "Polygon",
        geometryFunction: (coords, geom) => {
            if (!geom) {
                geom = new Polygon([]);
            }
            geom.setCoordinates(coords);
            return geom;
        },
        freehand: true,
        style: {
            'stroke-color': DEFAULT_FEATURE_STROKE_COLOR,
            'stroke-width': FEATURE_STROKE_WIDTH,
        }
    });

    map.addInteraction(draw);

    $(draw).on('drawstart', () => {
        resetDrawingData();
        hideFeaturePopup();
        $(map).on('pointermove', handlePointerMove)
    });

    $(draw).on('drawend', (e) => {
        $(map).off('pointermove', handlePointerMove)
        e.originalEvent.feature.setId(e.originalEvent.feature.ol_uid);

        addGeometryXYZCoordinate(firstCoordinate, previousTile);

        e.originalEvent.feature.renderFeaturePopup = renderFeaturePopup;

        let featureId = e.originalEvent.feature.getId();
        let featureCoordinate = [e.originalEvent.feature.getGeometry().flatCoordinates[0], e.originalEvent.feature.getGeometry().flatCoordinates[1]]
        let pixels = map.getPixelFromCoordinate(featureCoordinate);

        tiles.features[featureId] = {'tiles': Object.values(currentDrawingTiles), 'fill': FILL_MODE.outline}

        $(document).trigger($.Event(ON_DRAW_END, {featureId: featureId, x: pixels[0], y: pixels[1]}));

        resetDrawingData();
    });

    $(draw).on('drawabort', () => {
        resetDrawingData();
    })
}

export function enableEditMode(){
    // enable edit mode
    handleTriggerEditMode();
    resetTiles();
}

// mark feature to be filled
export function fillFeature(featureId){
    tiles.features[featureId].fill = FILL_MODE.fill;
}

// remove the feature from our data
export function undoFeature(featureId){
    delete tiles.features[featureId];
}

// check if there are features present in the state
export function areFeaturesPresent(){
    return Object.keys(tiles.freeDraw).length > 0 || Object.keys(tiles.features).length > 0;
}

// event handler for when the pointer is moving when drawing, capture position of mouse and store the tiles underneath
function handlePointerMove(e) {
    if(firstCoordinate == null){
        firstCoordinate = e.originalEvent.coordinate;
    }

    previousTile = addGeometryXYZCoordinate(e.originalEvent.coordinate, previousTile)
}

// convert drawing coordinate to tiles, and if needed interpolate tiles between last known tile and current tile
function addGeometryXYZCoordinate(coordinate, previousTile){
    let tileCoord =  tileGrid.getTileCoordForCoordAndZ(coordinate, maxZoom-1);

    let tileCoords = [tileGrid.getTileCoordForCoordAndZ(coordinate, maxZoom-1)];

    if(tileCoord !== previousTile && previousTile != null){
        // calculate min and max coordinates if not the same
        let xMin, xMax, yMin, yMax

        xMin = previousTile[1]
        xMax = tileCoord[1]
        yMin = previousTile[2]
        yMax = tileCoord[2]

        tileCoords = [...tileCoords, ...plotLine(xMin, yMin, xMax, yMax)]
    }

    for(let tileCoord of tileCoords){
        if(!(`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}` in currentDrawingTiles)){
            currentDrawingTiles[`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}`] = {z: tileCoord[0]+1,x: tileCoord[1],y: tileCoord[2]}
        }
    }

    return tileCoord;
}

// remove draw interaction from map, and reset the variable
function removeMapInteractions(){
    map.removeInteraction(draw);
    draw = null;
}

// turn off paint interaction on the map
export function handlePaintOff() {
    removeMapInteractions();
    map.set(FLAG_PAINT, 'false', true);
    $('#paint-line').prop('selected', false);
}

// reset data related to drawing
function resetDrawingData(){
    previousTile = null;
    firstCoordinate = null;
    currentDrawingTiles = {};
}

export {tiles}
export {clearEvents, handleTileClick, handleTileDrag, drawPolygonFromTiles}