import Map from "ol/Map";
import View from "ol/View";
import {defaults as defaultInteractions} from "ol/interaction";
import {eventDisplayLayer, onZoomChange} from "./layers/EventDisplay";
import {backgroundLayer} from "./layers/Background"
import {drawLayer} from "./layers/Draw";
import {eventLayer} from "./layers/Event";
import {MAP_DRAG_OFF, MAP_DRAG_ON, ON_MAP_CLICK_EVENT} from "./event-constants";
import DragPan from 'ol/interaction/DragPan.js';

import {wmsTileLayer} from "./layers/EventTileWMS"
import {maxZoom, tileWMSGrid} from "./layers/Common";
import {TileDebug} from "ol/source";
import TileLayer from "ol/layer/WebGLTile";

// Drag pan interaction control
let mapDrag = null;

let eventOnPointer = null;

// controls for the map
const controls = [

]

// layers we use on the map
const layers = [
    backgroundLayer,
    wmsTileLayer,
    eventLayer,
    drawLayer,
    eventDisplayLayer,
]

// the map view object
const view = new View({
    center: [0, 0],
    zoom: 2,
    constrainResolution: true,
    maxZoom: 12,
})

const map = new Map({
    target: "map",
    controls: controls,
    interactions: defaultInteractions({shiftDragZoom: false}),
    layers: layers,
    view: view
});

map.getView().on('change:resolution', () => {
    onZoomChange(map.getView().getZoom());
})

map.on('pointermove', function (evt) {
    if (evt.dragging) {
      return;
    }
    const data = wmsTileLayer.getData(evt.pixel);
    const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
    map.getTargetElement().style.cursor = hit ? 'pointer' : '';
  });

  map.on('singleclick', function (evt) {
    const data = wmsTileLayer.getData(evt.pixel);
    const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
    if(hit) {
        let tileCoord = tileWMSGrid.getTileCoordForCoordAndZ(evt.coordinate, maxZoom - 1);
        $(document).trigger($.Event(ON_MAP_CLICK_EVENT, {coordinates: [tileCoord[1], tileCoord[2]]}));
    }
  });

// Handle when the user clicks on the map
export function handleMapClicked(e){
    if(eventDisplayLayer.isVisible()){
        // check if any feature is under event
        let feature = eventDisplayLayer.getSource().getFeaturesAtCoordinate(e.originalEvent.coordinate);
        if(feature.length > 0){
            // trigger event to render to view event modal
            $(document).trigger($.Event(ON_MAP_CLICK_EVENT, {feature: feature[0]}));
        }
    }
}

export function startEventDetailsOnHover(){
    $(document).on('pointermove', displayEventDetailsOnHover);
}

export function stopEventDetailsOnHover(){
    $(document).off('pointermove', displayEventDetailsOnHover);
    $('#event-hover-container').hide();
}

/**
 * Event handler for pointer movement
 * Check if the pointers current position has an event underneath it
 * if it does, render the eventDialog widget to show that events details
 * @param e
 */
export function displayEventDetailsOnHover(e){
    let pixels = [e.clientX, e.clientY];
    // function to restrict searching to one layer
    let layerFilter = (layer) => layer === eventDisplayLayer;

    // apply above layer filter to getFeaturesAtPixel function, openlayers only searches for events within the event display layer
    let features = map.getFeaturesAtPixel(pixels, {layerFilter});

    if(features.length > 0){
        // if there are features, set current event id to first feature selected, and check if its a new event or an event we've already visited
        let currentEvent = features[0].getGeometry().getProperties().id;
        if(eventOnPointer === null){
            // a new event, so create a dialog to follow the mouse with the events details
            eventOnPointer = currentEvent;
            $('#event-hover-container').eventDialog("updateData", eventOnPointer, e.clientX, e.clientY);
            $('#event-hover-container').show();
        }else if(eventOnPointer !== currentEvent){
            // not the same event as what was previously selected, destroy the old eventDialog and create a new one
            eventOnPointer = currentEvent;
            $('#event-hover-container').eventDialog("updateData", eventOnPointer, e.clientX, e.clientY);
        }
    }else if(features.length === 0 && eventOnPointer !== null){
        eventOnPointer = null;
        $('#event-hover-container').hide();
    }
}

function setDragPan(){
    map.interactions.forEach((control) => {
        if(control instanceof DragPan){
            mapDrag = control;
        }
    })
}

function disableDragPan(){
    mapDrag.setActive(false);
}

function enableDragPan(){
    mapDrag.setActive(true);
}

$(document).ready(setDragPan);
$(document).on(MAP_DRAG_ON, enableDragPan);
$(document).on(MAP_DRAG_OFF, disableDragPan);

// DEBUGGING CODE
let debugLayer = new TileLayer({
    source: new TileDebug()
});

function showDebugTiles(){
    map.addLayer(debugLayer);
}

function hideDebugTiles(){
    map.removeLayer(debugLayer);
}

window.showDebugTiles = showDebugTiles;
window.hideDebugTiles = hideDebugTiles;

export {map};