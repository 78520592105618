import {eventDisplayLayer} from "../layers/EventDisplay";
import {
    ATTRIBUTE_LABELS,
    DELETE_EVENT_ACTION, DELETE_EVENT_GROUP_ACTION, DELETE_EVENT_GROUP_MESSAGE, DELETE_EVENT_MESSAGE,
    ERROR_PROCESSING_DELETE, EVENT_NOT_FOUND_IN_YEAR,
    FOCUSED_FEATURE_DURING_VIEW, INVALID_DELETE_ACTION,
    PLAIN_TEXT_FIELD,
    RICH_TEXT_FIELD,
    SELECTED_LANGUAGE_CODE,
    SERVER_PROCESSING_DELETE,
} from "../constants";
import Handlebars from "handlebars";
import {
    ON_CLOSE_VIEW_EVENT,
    ON_CONFIRM_DELETE_EVENT,
    ON_DELETE_EVENT,
    ON_EDIT_ATTRIBUTES_PRESSED,
    ON_EDIT_TILES_PRESSED,
    ON_EVENT_CLICKED, YEAR_CHANGED
} from "../event-constants";
import {callAPI} from "../model/Request";
import {map} from "../Map";
import {renderErrorMessage, renderInfoMessage} from "../control/InfoPopup";
import {getSelectedYear} from "../control/YearSlider";

let eventId;
let groupId;
let deleteAction;
let eventName;
let eventDetails;

const deleteActionMapping = {
    [DELETE_EVENT_ACTION]: DELETE_EVENT_MESSAGE,
    [DELETE_EVENT_GROUP_MESSAGE]: DELETE_EVENT_GROUP_MESSAGE
}

async function getFreeFormAttributeData(eventTypeID){
    return await callAPI('get-event-type-attributes', null, [eventTypeID])
        .then((result) => result)
}

// prepare the data for this events attributes
async function prepareEventAttributes(eventTypeAttributes, eventType){
    if (eventTypeAttributes === null){
        return;
    }

    let freeFormAttributes = await getFreeFormAttributeData(eventType);

    let attributes = [];

    freeFormAttributes.forEach((attribute) => {
        if(attribute.name in eventTypeAttributes){
            attributes.push({
                attributeName: attribute.label[SELECTED_LANGUAGE_CODE],
                attributeValue: eventTypeAttributes[attribute.name][SELECTED_LANGUAGE_CODE],
                attributeType: attribute.type,
            });
        }
    });

    return attributes;
}

// prepare all event attributes to show to a user
async function prepareViewEventData(event){
    // prepare list of objects 'attributes' with attributeName and attributeValue keys for each datapoint
    let attributes = [];
    if(event.name !== undefined){
        attributes.push({attributeName: ATTRIBUTE_LABELS['Name'][SELECTED_LANGUAGE_CODE], attributeValue: event.name, attributeType: PLAIN_TEXT_FIELD});
    }

    if(event.description !== undefined){
        attributes.push({attributeName: ATTRIBUTE_LABELS['Description'][SELECTED_LANGUAGE_CODE], attributeValue: event.description, attributeType: RICH_TEXT_FIELD});
    }

    let year;

    if(event.date !== undefined){
        let date = new Date(event.date);
        year = date.getFullYear();
    }else{
        year = event.year;
    }

    attributes.push({
        attributeName: ATTRIBUTE_LABELS['Year'][SELECTED_LANGUAGE_CODE],
        attributeValue: year,
        attributeType: PLAIN_TEXT_FIELD,
        isYear: true,
        ...event.adjacent_events
    });

    if(event.attributes !== undefined){
        attributes = [...attributes, ...await prepareEventAttributes(event.attributes, event.event_type_id)];
    }

    let isValid = false;
    if(attributes.length > 1){
        isValid = true;
    }

    let template = Handlebars.compile('{{>view-event-details}}')
    let htmlContent = template({attributes: attributes, header: event.header, isValid: isValid});
    $('#event-details-template').html(htmlContent);
}

// prepare all the data and change the state to view event details
async function renderViewEventModal(eventData){
    eventId = eventData.id || eventId;
    groupId = eventData.event_group_id || groupId;
    eventName = eventData.name || eventName;
    eventDetails = eventData || eventDetails;
    await prepareViewEventData(eventData);

    $('#edit-event-attributes').click(() => $(document).trigger(ON_EDIT_ATTRIBUTES_PRESSED));
    $('#close-view-event-sidebar').click(() => $(document).trigger(ON_CLOSE_VIEW_EVENT));
    $('#edit-event-tiles').click(() => $(document).trigger(ON_EDIT_TILES_PRESSED));
    $('#center-event').click(handleCenterEvent);
    $('#toggle-delete-menu').click(toggleDeleteMenu);
    $('#delete-event-menu').on('close-menu', deleteActionSelected);
    $('#next-event').click(getAdjacentEvent);
    $('#previous-event').click(getAdjacentEvent);

    $(document).trigger(ON_EVENT_CLICKED);
    $(document).on(YEAR_CHANGED, getGroupEventFromYear);
}

export function getEventDetailsByPixels(e){
    let year = getSelectedYear();
    callAPI('get-event-on-year-at-xy', null, [year, ...e.coordinates])
        .then((event) => {
            renderViewEventModal(event);
        })
}

/**
 * Get the event from the year set on the year slider or input field
 * @param e
 */
function getGroupEventFromYear(e){
    if(!['year-slider', 'year-input'].includes(e.target.activeElement.id)){
        return;
    }

    let year = e.year;
    callAPI('get-event-by-event-group-and-year', null, [groupId, year])
        .then((event) => {
            // rerender the view as we do below, but with the new event gotten from this request, chances are it can be empty as well, so rendering needs to handle that

            if(Object.keys(event).length === 1){
                // only one thing exists (adjacent events), rerender the modal with only the year and no event found header
                event = {
                    ...event,
                    year: year,
                    header: EVENT_NOT_FOUND_IN_YEAR(eventName),
                }
            }

            $(document).off(YEAR_CHANGED, getGroupEventFromYear);
            renderViewEventModal(event);
        })
}

function getAdjacentEvent(e){
    let adjacentEventID = $(e.currentTarget).attr('data-event-id');
    let adjacentYear = $(e.currentTarget).attr('data-event-year');
    callAPI('get-event-by-id', null, [adjacentEventID])
        .then((adjacentEvent) => {
            $(document).off(YEAR_CHANGED, getGroupEventFromYear);
            renderViewEventModal(adjacentEvent);

            // change the year to the year of the adjacent event
            $(document).trigger($.Event(YEAR_CHANGED, {year: adjacentYear}));
        })
}

function toggleDeleteMenu(){
    const menuElement = $('#delete-event-menu');
    menuElement.prop('open', !menuElement.prop('open'));
}

function deleteActionSelected(e){
    deleteAction = $(e.target).attr('id');

    $('#delete-message').html(deleteActionMapping[deleteAction]);

    switch(deleteAction){
        case DELETE_EVENT_ACTION:
            $(document).trigger($.Event(ON_DELETE_EVENT, {deleteAction: deleteAction, id: eventId}));
            break;
        case DELETE_EVENT_GROUP_ACTION:
            $(document).trigger($.Event(ON_DELETE_EVENT, {deleteAction: deleteAction, id: groupId}));
            break;
        default:
            throw new Error(INVALID_DELETE_ACTION);
    }
}

function handleCenterEvent(){
    let selectedFeature = eventDisplayLayer.getSource().getFeatureById(eventId);
    let extent = selectedFeature.getGeometry().getExtent();
    map.getView().fit(extent, FOCUSED_FEATURE_DURING_VIEW);
}

// handling closing the view event details modal, happens after transitioning out of view event details
export function closeViewEventHandler(){
    eventDisplayLayer.setVisible(true);
    $(document).off(YEAR_CHANGED, getGroupEventFromYear);
    // remove all attributes related to this event
    $('#view-event-details').addClass('hidden');
}

export function performDeleteRequest(deleteAction, id){
    callAPI(deleteAction, null, [id])
        .then(() => {
            groupId = null;
            eventId = null;

            $(document).trigger(ON_CONFIRM_DELETE_EVENT);
            renderInfoMessage({text: SERVER_PROCESSING_DELETE});
        })
        .catch(() => {
            renderErrorMessage({text: ERROR_PROCESSING_DELETE});
        })
}

export function getEventID(){
    return eventId;
}

export function getEventData(){
    return eventDetails;
}