import {
    ON_EVENT_CLICKED,
    ON_ADD_EVENT_PRESSED,
    ON_CONFIRM_DELETE_EVENT,
    ON_CONFIRM_DISCARD_CHANGES_TO_TILES,
    ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES,
    ON_CONFIRM_DISCARD_NEW_EVENT,
    ON_CREATE_SAVE_PRESSED,
    ON_DELETE_EVENT,
    ON_DISCARD_CHANGES,
    ON_DRAW_END,
    ON_EDIT_TILES_PRESSED,
    ON_ESC_KEY_PRESSED,
    ON_FILL_DRAWING,
    ON_SAVE_ATTRIBUTES_PRESSED,
    ON_SAVE_PRESSED,
    ON_CANCEL_DISCARD_NEW_EVENT,
    ON_CANCEL_DISCARD_CHANGES_TO_TILES,
    ON_CANCEL_DISCARD_EVENT_ATTRIBUTE_CHANGES,
    ON_FREE_FORM_BUTTON_PRESSED,
    ON_CREATE_EVENT_SUCCESS,
    ON_CANCEL_INSERT_NEW_EVENT,
    ON_CLOSE_VIEW_EVENT,
    ON_CANCEL_DELETE_EVENT,
    ON_ERROR_SAVING_TILES,
    ON_SAVE_SUCCESS,
    ON_EDIT_ATTRIBUTES_PRESSED,
    ON_CREATE_EVENT_FAIL,
    ON_CLEAR_DRAWING, ERROR_UPDATE_ATTRIBUTES
} from "../event-constants";

export const VIEW_EVENTS_STATE = 'VIEW_EVENTS';
export const SAVING_TIlES_STATE = 'SAVING_TILES';
export const SAVING_ATTRIBUTES_STATE = 'SAVING_ATTRIBUTES';
export const FREE_FORM_DRAW_STATE = "FREE_FORM_DRAW";
export const SHAPE_CLOSED_STATE = "SHAPE_CLOSED";
export const INSERT_EVENT_DETAILS_STATE = "INSERT_EVENT_DETAILS";
export const DISCARD_CHANGES_STATE = "DISCARD_CHANGES";
export const VIEW_EVENT_DETAILS_STATE = "VIEW_EVENT";
export const EDIT_EVENT_ATTRIBUTES_STATE = "EDIT_EVENT_ATTRIBUTES";
export const PAINT_STATE = "PAINT";
export const DELETE_CONFIRMATION_STATE = "DELETE_CONFIRMATION";

// graph each of events, from states -> to state
export const TRANSITION_GRAPH = {
    [ON_EVENT_CLICKED]: {from: [VIEW_EVENTS_STATE, VIEW_EVENT_DETAILS_STATE], to: VIEW_EVENT_DETAILS_STATE},
    [ON_ADD_EVENT_PRESSED]: {from: [VIEW_EVENTS_STATE], to: FREE_FORM_DRAW_STATE},
    [ON_DRAW_END]: {from: [FREE_FORM_DRAW_STATE, SHAPE_CLOSED_STATE], to: SHAPE_CLOSED_STATE},
    [ON_FILL_DRAWING]: {from: [SHAPE_CLOSED_STATE], to: FREE_FORM_DRAW_STATE},
    [ON_CLEAR_DRAWING]: {from: [SHAPE_CLOSED_STATE], to: FREE_FORM_DRAW_STATE},
    [ON_DISCARD_CHANGES]: {from: [FREE_FORM_DRAW_STATE, SHAPE_CLOSED_STATE, EDIT_EVENT_ATTRIBUTES_STATE, PAINT_STATE], to: DISCARD_CHANGES_STATE},
    [ON_ESC_KEY_PRESSED]: {from: [VIEW_EVENT_DETAILS_STATE, ], to: VIEW_EVENTS_STATE},
    [ON_CREATE_SAVE_PRESSED]: {from: [FREE_FORM_DRAW_STATE, SHAPE_CLOSED_STATE], to: INSERT_EVENT_DETAILS_STATE},
    [ON_EDIT_TILES_PRESSED]: {from: [VIEW_EVENT_DETAILS_STATE], to: PAINT_STATE},
    [ON_SAVE_PRESSED]: {from: [PAINT_STATE], to: SAVING_TIlES_STATE},
    [ON_SAVE_ATTRIBUTES_PRESSED]: {from: [INSERT_EVENT_DETAILS_STATE, EDIT_EVENT_ATTRIBUTES_STATE], to: SAVING_ATTRIBUTES_STATE},
    [ON_DELETE_EVENT]: {from: [VIEW_EVENT_DETAILS_STATE], to: DELETE_CONFIRMATION_STATE},
    [ON_CONFIRM_DELETE_EVENT]: {from: [DELETE_CONFIRMATION_STATE], to: VIEW_EVENTS_STATE},
    [ON_CONFIRM_DISCARD_CHANGES_TO_TILES]: {from: [DISCARD_CHANGES_STATE], to: VIEW_EVENT_DETAILS_STATE},
    [ON_CONFIRM_DISCARD_NEW_EVENT]: {from: [DISCARD_CHANGES_STATE], to: VIEW_EVENTS_STATE},
    [ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES]: {from: [DISCARD_CHANGES_STATE], to: VIEW_EVENT_DETAILS_STATE},
    [ON_CANCEL_DISCARD_NEW_EVENT]: {from: [DISCARD_CHANGES_STATE], to: FREE_FORM_DRAW_STATE},
    [ON_CANCEL_DISCARD_CHANGES_TO_TILES]: {from: [DISCARD_CHANGES_STATE], to: PAINT_STATE},
    [ON_CANCEL_DISCARD_EVENT_ATTRIBUTE_CHANGES]: {from: [DISCARD_CHANGES_STATE], to: EDIT_EVENT_ATTRIBUTES_STATE},
    [ON_FREE_FORM_BUTTON_PRESSED]: {from: [SHAPE_CLOSED_STATE], to: FREE_FORM_DRAW_STATE},
    [ON_CREATE_EVENT_SUCCESS]: {from: [SAVING_ATTRIBUTES_STATE, SAVING_TIlES_STATE], to: VIEW_EVENTS_STATE},
    [ON_CREATE_EVENT_FAIL]: {from: [SAVING_ATTRIBUTES_STATE], to: INSERT_EVENT_DETAILS_STATE},
    [ON_CANCEL_INSERT_NEW_EVENT]: {from: [INSERT_EVENT_DETAILS_STATE], to: FREE_FORM_DRAW_STATE},
    [ON_CLOSE_VIEW_EVENT]: {from: [VIEW_EVENT_DETAILS_STATE], to: VIEW_EVENTS_STATE},
    [ON_CANCEL_DELETE_EVENT]: {from: [DELETE_CONFIRMATION_STATE], to: VIEW_EVENT_DETAILS_STATE},
    [ON_ERROR_SAVING_TILES]: {from: [SAVING_TIlES_STATE], to: PAINT_STATE},
    [ON_SAVE_SUCCESS]: {from: [SAVING_ATTRIBUTES_STATE, SAVING_TIlES_STATE], to: VIEW_EVENTS_STATE},
    [ON_EDIT_ATTRIBUTES_PRESSED]: {from: [VIEW_EVENT_DETAILS_STATE], to: EDIT_EVENT_ATTRIBUTES_STATE},
    [ERROR_UPDATE_ATTRIBUTES]: {from: [SAVING_ATTRIBUTES_STATE], to: EDIT_EVENT_ATTRIBUTES_STATE},
}