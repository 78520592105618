import {getWidth} from "ol/extent";
import {get as getProjection} from "ol/proj";
import {TileGrid} from "ol/tilegrid";
import Polygon from "ol/geom/Polygon";

export const tileSize = 256;
export const tileWMSSize = 256;

export const zoomRatios = [
    10,
    11,
    12,
    13,
    14,
    15
]

export const backgroundZoomRatios = [
    2, // 16 Tiles (8x8)
    3, // 64 Tiles (16x16)
    4, // 256 Tiles (23x23)
    5, // 1024 Tiles (32x32)
    6  // 4096 Tiles (45x45)
]

// offsets for each brush size
export const brushSizeLevels = {
    1: [[0, 0]], // 1x1 area (only the center pixel)
    2: [ // 3x3 area
        [0, -1], [1, -1], [1, 0],
        [1, 1], [0, 1], [-1, 1],
        [-1, 0], [-1, -1], [0, 0]
    ],

    3: Array.from({ length: 9 }, (_, x) =>
        Array.from({ length: 9 }, (_, y) => [x - 4, y - 4])).flat(),

    4: Array.from({ length: 21 }, (_, x) =>
        Array.from({ length: 21 }, (_, y) => [x - 10, y - 10])).flat()
};

// function to convert our zoom ratios into resolutions usable by openlayers
export const getResolutions = (tileSize, zoomArray) => {
    let resolutions = [];

    for(let ratio of zoomArray){
        let resolution = (getWidth(getProjection('EPSG:3857').getExtent()) / tileSize) / Math.pow(2, ratio);
        resolutions.push(resolution);
    }

    return resolutions;
}

// Defining our own projection
export const tileGrid = new TileGrid({
    extent: getProjection('EPSG:3857').getExtent(),
    resolutions: getResolutions(tileSize, zoomRatios),
    tileSize: [tileSize, tileSize]
})

export const tileWMSGrid = new TileGrid({
    extent: getProjection('EPSG:3857').getExtent(),
    resolutions: getResolutions(tileWMSSize, zoomRatios),
    tileSize: [tileWMSSize, tileWMSSize]
})

export const maxZoom = 5;
export const minZoom = 1;

export const EPSG_3857_RATIO = {
    x: 2445.984905,
    y: 2445.984905
}

export const EPSG_3857_OFFSETS = {
    x: 20037509.34,
    y: 20037509.34
}

let left = -EPSG_3857_OFFSETS.x;
let right = -EPSG_3857_OFFSETS.x + EPSG_3857_RATIO.x;
let top = EPSG_3857_OFFSETS.y;
let bottom = EPSG_3857_OFFSETS.y - EPSG_3857_RATIO.y;

export const TOP_LEFT_TILE = new Polygon([
    [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
        [left, bottom]
    ]
]);