import {DEFAULT_BRUSH_SIZE, MAX_BRUSH_SIZE, MIN_BRUSH_SIZE} from "../constants";

let brushSize = DEFAULT_BRUSH_SIZE;

export function handleInitializeFillSizeSlider(){
    const slider = $('#brush-size');

    // setting range extent and default value for slider
    slider.attr('min', `${MIN_BRUSH_SIZE}`);
    slider.attr('max', `${MAX_BRUSH_SIZE}`);
    slider.attr('value', `${MIN_BRUSH_SIZE}`);

    slider.on('change', handleSliderChange);
}

export function hideSlider(){
    // hide slider
    let brushSlider = $('#brush-size');
    brushSlider.addClass('hidden');
}

export function showSlider(){
    // show slider
    let brushSlider = $('#brush-size');
    brushSlider.removeClass('hidden');
}

function handleSliderChange(e){
    brushSize = e.detail;
}

export function getBrushSize(){
    return brushSize;
}