
$.widget("custom.confirmDelete", {
    options: {
        confirmButton: null,
        cancelButton: null,
        confirmDeleteFunction: null,
        cancelDeleteFunction: null
    },
    _create: function(){
        this._on($(this.options.confirmButton), {'click': this._onConfirmDelete});
        this._on($(this.options.cancelButton), {'click': this._onCancelDelete});
        this._on($(this.element), {'cancel': this._onCancelDelete})
    },
    show: function(data){
        $(this.element).prop('open', true);

        $(this.options.confirmButton).prop('data-delete-action', data.deleteAction);
        $(this.options.confirmButton).prop('data-id', data.id);
    },
    hide: function(){
        $(this.element).prop('open', false);
        $(this.options.confirmButton).removeProp('data-delete-action');
        $(this.options.confirmButton).removeProp('data-id');
    },
    _onConfirmDelete: function(){
        let deleteAction = $(this.options.confirmButton).prop('data-delete-action');
        let deleteId = $(this.options.confirmButton).prop('data-id');

        if(this.options.confirmDeleteFunction != null){
            this.options.confirmDeleteFunction(deleteAction, deleteId);
        }
    },
    _onCancelDelete: function(){
        if(this.options.cancelDeleteFunction != null){
            this.options.cancelDeleteFunction();
        }
    }
});