import {eventTypes, getEventTypeID} from "../control/NewEvent";
import {callAPI} from "../model/Request";
import {prepareEventAttributesForm, editor} from "./EventAttributesModal";
import {
    ON_CREATE_EVENT_FAIL,
    ON_CREATE_EVENT_SUCCESS
} from "../event-constants";
import {tiles} from "../features";
import {
    ATTRIBUTE_LABELS, ENUM_TYPES,
    ERROR_PROCESSING_EVENT_MESSAGE,
    SELECTED_LANGUAGE_CODE,
    SERVER_PROCESSING_EVENT_MESSAGE
} from "../constants";
import {createPopupOptions, renderErrorMessage, renderInfoMessage} from "../control/InfoPopup";
import {getSelectedYear} from "../control/YearSlider";

const DURATION_START_ID = 'duration-start';
const DURATION_END_ID = 'duration-end';
const EVENT_NAME_ID = 'event-name';
const EVENT_DESCRIPTION_ID = 'description';
const EVENT_COLOR_ID = 'create-event-color';
const MODAL_HEADER = (eventTypeName) => `Create New ${eventTypeName}`;
const MODAL_ID = 'insert-event-details';

export async function renderNewEventModal(){
    let templateObject = null;

    // check if the modal already exists, if it does, no need to reinitialize the listeners
    if ($('#insert-event-details').length === 0) {
        templateObject = await prepareNewEventTemplate();
    }

    prepareEventAttributesForm(templateObject);
}

// get all event attributes for selected event type and
async function getAttributesData(){
    let eventTypeID = getEventTypeID();
    return await callAPI('get-event-type-attributes', null, [eventTypeID])
        .then((result) => result)
}

// Prepare the data for the new event form
async function prepareNewEventTemplate() {
    let durationStartValue = getSelectedYear();
    let durationEndValue = getSelectedYear();
    let name = "";
    let description = "";

    let freeFormAttributes = await getAttributesData();

    let attributes = [];
    attributes.push({name: EVENT_NAME_ID, type: ENUM_TYPES['text'], label: ATTRIBUTE_LABELS['Name'], value: name, isPermanent: true});
    attributes.push({name: EVENT_DESCRIPTION_ID, type: ENUM_TYPES['rich-text'], label: ATTRIBUTE_LABELS['Description'], isPermanent: true});
    attributes.push({row: [
        {name: DURATION_START_ID, type: ENUM_TYPES['number'], label: ATTRIBUTE_LABELS['duration_start'], value: durationStartValue, isPermanent: true, isYear: true},
        {name: DURATION_END_ID, type: ENUM_TYPES['number'], label: ATTRIBUTE_LABELS['duration_end'], value: durationEndValue, isPermanent: true, isYear: true}],
    });

    attributes = attributes.concat(freeFormAttributes);
    let selectedColor = AVAILABLE_COLORS.find((color) => color.isDefault).value;

    let eventTypeName = getEventTypeName();

    return {
        selectedColor: selectedColor,
        colors: AVAILABLE_COLORS,
        attributes: attributes,
        modalHeader: MODAL_HEADER(eventTypeName),
        modalID: MODAL_ID,
        description: description,
        language: SELECTED_LANGUAGE_CODE,
    }
}

// look for the event name within the event types array
function getEventTypeName(){
    let eventTypeId = getEventTypeID();
    let eventType = eventTypes.find((eventType) => eventType.id == eventTypeId);
    return eventType.name;
}

// build attribute object to send to server
function handleEventAttributes(){
    let attributeElements = $('.attribute-input')
    let attributeObject = {};

    // get attribute data and build its object to return
    Array.from(attributeElements).forEach((attributeElement) => {
        let value = attributeElement.value === null ? "" : attributeElement.value;
        attributeObject[attributeElement.id] = {
            [SELECTED_LANGUAGE_CODE]: value,
            type: attributeElement.type,
        }
    })

    return attributeObject;
}

export function handleSaveEvent(){
    // get the description and event type for this event
    let eventDescription = editor.getData();
    let eventTypeId = getEventTypeID();

    // get attribute data for this event
    let attributeObject = handleEventAttributes();

    let newEvent = {
        'type': eventTypeId,
        'description': eventDescription,
        'tiles': Object.values(tiles.freeDraw),
        'geometries': Object.values(tiles.features),
        'attributes': attributeObject
    }

    // parse through all input fields for this event
    let fields = {
        'name': EVENT_NAME_ID,
        'duration_start': DURATION_START_ID,
        'duration_end': DURATION_END_ID,
        'color_hex': EVENT_COLOR_ID
    }

    Object.entries(fields).forEach(([name, field]) => {
        const value = $(`#${field}`).val();
        newEvent[name] = value.length === 0 ? null : value;
    });

    callAPI('create-new-event', newEvent, [])
        .then(()=>{
            $(document).trigger(ON_CREATE_EVENT_SUCCESS);
            renderInfoMessage(createPopupOptions({text: SERVER_PROCESSING_EVENT_MESSAGE}));
        })
        .catch((err) => {
            $(document).trigger($.Event(ON_CREATE_EVENT_FAIL, {error: err, eventObject: newEvent}));

            if(![504, 502].includes(err.status)){
                const data = text && JSON.parse(text);
                const error = (data && data.message) || err.statusText;

                renderErrorMessage(createPopupOptions({text: ERROR_PROCESSING_EVENT_MESSAGE(error)}));
            }
        })
}