import TileLayer from 'ol/layer/WebGLTile.js';
import TileWMS from 'ol/source/TileWMS.js';
import {createXYZ} from 'ol/tilegrid.js';
import {getURL} from "../model/UrlBuilder";
import {getSelectedYear} from "../control/YearSlider";

// let wmsURL = getURL('event-wms-tiles');
let wmsURL = getURL('tapestry-wms-tiles');

export const wmsTileLayer = new TileLayer({
    source: new TileWMS({
      url: wmsURL,
      // TODO: Make this a default parameter based on the initialization
      params: {'LAYERS': 'events', 'TILED': true, 'year': 2025,'zoom': 1},
      // projection: 'EPSG:4326',
      // Source tile grid (before reprojection)
      tileGrid: createXYZ({
        // extent: [0, 0, 180, 180],
        // // extent: [0, 0, 22584, 22584],
        // maxResolution: 360 / 512,
        // maxZoom: 10,
      }),
      // tileGrid: tileWMSGrid,
      tileLoadFunction: (imageTile, src) => {
        let year = getSelectedYear();
        // TODO: Add parameters to include year and maybe zoom level?
        // imageTile.getImage().src = `${src}&year=${year}`;
        imageTile.getImage().src = `${src}`;
      },
      // Countries have transparency, so do not fade tiles:
      transition: 0,
      // interpolate: false
      // Use a higher resolution when between layers
    }),
})