import {tiles} from "../features";
import {ON_ERROR_SAVING_TILES, ON_SAVE_SUCCESS} from "../event-constants";
import {callAPI} from "../model/Request";
import {createPopupOptions, renderInfoMessage} from "../control/InfoPopup";
import {SERVER_PROCESSING_EVENT_MESSAGE} from "../constants";

export function handleUpdateEvent(featureId, eventData){
    let tileDelta = {
        'delete': Object.values(tiles.freeDraw).filter(tile=>tile.delete),
        'new': Object.values(tiles.freeDraw).filter(tile=>tile.new)
    }

    let updatedEvent = {
        'id': eventData.id,
        'name': eventData.name,
        'event_group_id': eventData.event_group_id,
        'tiles': tileDelta,
        'color': eventData.color_hex,
    }

    callAPI('edit-event', updatedEvent, [])
        .then(()=>{
            $(document).trigger($.Event(ON_SAVE_SUCCESS));
            renderInfoMessage(createPopupOptions({text: SERVER_PROCESSING_EVENT_MESSAGE}));
        })
        .catch((err)=>{
            $(document).trigger($.Event(ON_ERROR_SAVING_TILES, {error: err}));
        })

}