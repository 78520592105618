import {callAPI} from "./Request";
import {
    SERVER_DOWN_ERROR_MESSAGE,
    SERVER_DOWN_TRYING_AGAIN_MESSAGE,
    CONNECTION_REESTABLISHED_MESSAGE, RECONNECTING_MESSAGE
} from "../constants";
import {createPopupOptions, renderErrorMessage, renderInfoMessage} from "../control/InfoPopup";


let healthCheckAttempts = 0;
let healthCheckInProgress = false;
const TIMEOUT_DURATION = 10000;

/**
 * Function to check the heartbeat of the back-end
 */
export async function checkServerStatus(){
    if(!healthCheckInProgress){
        renderInfoMessage(createPopupOptions({text: RECONNECTING_MESSAGE}));
        healthCheckInProgress = true;
        healthCheckAttempts = 0;
        const maxAttempts = 3;

        while(healthCheckAttempts < maxAttempts){
            try{
                await callAPI('health-check')

                // server responded, so reset the variables and break the loop
                renderInfoMessage(createPopupOptions({text: CONNECTION_REESTABLISHED_MESSAGE}));
                healthCheckInProgress = false;
                healthCheckAttempts = 0;
                break;
            }catch(e){
                // call api had rejected the promise, so no response from server
                healthCheckAttempts++;

                if(healthCheckAttempts < maxAttempts){
                    renderErrorMessage(createPopupOptions({text: SERVER_DOWN_TRYING_AGAIN_MESSAGE, hideAfter: TIMEOUT_DURATION, stack: 2}));

                    // Got this bit of code from: https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
                    await new Promise(resolve => setTimeout(resolve, TIMEOUT_DURATION));
                }else if(healthCheckAttempts === maxAttempts){

                    renderErrorMessage(createPopupOptions({text: SERVER_DOWN_ERROR_MESSAGE, hideAfter: false}));
                    healthCheckInProgress = false;
                    healthCheckAttempts = 0;
                    break;
                }
            }
        }
    }
}