export const ENGLISH_LANGUAGE_CODE = "en-US";
export const SELECTED_LANGUAGE_CODE = ENGLISH_LANGUAGE_CODE;

export const DEFAULT_FEATURE_STROKE_COLOR = "rgba(255,204,51,1)"; // #FFCC33
export const DEFAULT_FEATURE_FILL_COLOR = "rgba(255,255,51,0.5)"; // #FFCC33
export const NEW_FEATURE_FILL_COLOR = "rgba(23,255,0,1)"; // #17FF00
export const DELETE_FEATURE_FILL_COLOR = "rgba(255,79,79,1)"; // #FF4F4F
export const FEATURE_STROKE_WIDTH = 3;
export const FEATURE_LAYER_OPACITY = 0.5;

export const ATTRIBUTE_LABELS = {
    'Name': {[ENGLISH_LANGUAGE_CODE]: 'Name'},
    'Description': {[ENGLISH_LANGUAGE_CODE]: 'Description'},
    'Year': {[ENGLISH_LANGUAGE_CODE]: 'Year'},
    'duration_start': {[ENGLISH_LANGUAGE_CODE]: 'Duration Start'},
    'duration_end': {[ENGLISH_LANGUAGE_CODE]: 'Duration End'},
}

export const RICH_TEXT_FIELD = 'rich-text';
export const PLAIN_TEXT_FIELD = 'plain-text';

export const DEFAULT_YEAR = new Date().getFullYear();
export const MAX_YEAR = new Date().getFullYear();
export const MIN_YEAR = 0;
export const DEFAULT_BRUSH_SIZE = 1;
export const MIN_BRUSH_SIZE = 1;
export const MAX_BRUSH_SIZE = 4;
export const DRAWING_MODE = {
    'add': 'add',
    'remove': 'remove'
}

export const FILL_MODE = {
    'fill': 'fill',
    'outline': 'outline'
}

export const FOCUSED_FEATURE = {padding: [500,500,500,500]};
export const FOCUSED_FEATURE_DURING_VIEW = {padding: [500, 200, 500, 1000]};

export const DEFAULT_ZOOM_LEVEL = 1;

export const SERVER_DOWN_ERROR_MESSAGE = "No response from the server, please try again later";
export const SERVER_DOWN_TRYING_AGAIN_MESSAGE = "No response from the server, trying again in 10 seconds";
export const CONNECTION_REESTABLISHED_MESSAGE = "Reestablished connection with server";
export const RECONNECTING_MESSAGE = "Attempting to reestablish connection to the server";
export const SERVER_PROCESSING_EVENT_MESSAGE = "The server is processing your event. Please Wait";
export const ERROR_PROCESSING_EVENT_MESSAGE = (err) => `There was an error processing your event. ${err}`;
export const EVENT_NOT_FOUND_MESSAGE = "Event not found";

export const DELETE_EVENT_ACTION = "delete-event";
export const DELETE_EVENT_GROUP_ACTION = "delete-event-group";
export const SERVER_PROCESSING_DELETE = "The server is processing the delete. Please Wait";
export const ERROR_PROCESSING_DELETE = "There was an error processing the delete.";
export const DELETE_EVENT_MESSAGE = "Delete Event?";
export const DELETE_EVENT_GROUP_MESSAGE = "Delete Event Group?";
export const INVALID_DELETE_ACTION = "Invalid Delete Action";

export const EVENT_NOT_FOUND_IN_YEAR = (name) => `No Events for ${name} in this year`;

export const ENUM_TYPES = {
    'text': 'text',
    'rich-text': 'rich-text',
    'number': 'number',
    'date': 'date',
    'textarea': 'textarea',
    'url': 'url'
};