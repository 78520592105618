import Handlebars from "handlebars";
import {eventDisplayLayer} from "../layers/EventDisplay";
import {SELECTED_LANGUAGE_CODE} from "../constants";
import {ON_EVENT_TYPES_READY} from "../event-constants";

let EVENT_TYPE_SUMMARY_KEYS = {};

$(document).on(ON_EVENT_TYPES_READY, initializeEventSummaryFields)

function initializeEventSummaryFields(e){
    let eventTypes = e.types;
    for(let eventType of eventTypes){
        EVENT_TYPE_SUMMARY_KEYS[`${eventType.name}`] = eventType.summary_attributes;
    }
}

$.widget("custom.eventDialog", {
    options: {
        eventID: null,
        dialogElement: null
    },

    _create: function(){
        this._on(document, {
            'pointermove': this._moveDialog
        })
    },

    updateData: function(eventID, mouseX, mouseY){
        this.options.eventID = eventID;
        let eventData = eventDisplayLayer.getSource().getFeatureById(this.options.eventID).getGeometry().getProperties();
        let attributes = this._getAttributes(eventData);
        this._render(attributes, mouseX, mouseY);
    },

    _render: function(attributes, mouseX, mouseY){
        let template = Handlebars.compile('{{>event-details-dialog}}')
        let htmlContent = template({attributes: attributes, mouseX, mouseY});
        $(this.element).html(htmlContent);
    },

    _moveDialog: function(e){
        // check if dialog element is set and exists on the dom itself
        if(this.options.dialogElement != null && document.getElementById(this.options.dialogElement)){
            let element = document.getElementById(this.options.dialogElement);
            element.style.left = `${e.clientX}px`;
            element.style.top = `${e.clientY}px`;
        }
    },

    _getAttributes: function(eventData){
        let attributes = [];

        let attributeKeys = EVENT_TYPE_SUMMARY_KEYS[eventData.event_type]

        for(let key of attributeKeys){
            // need to traverse eventData further
            if(key.includes('.')){
                let keys = key.split('.');
                let attributeValue = eventData.attributes[keys[keys.length - 1]][SELECTED_LANGUAGE_CODE];
                attributes.push({attributeName: keys[keys.length - 1], attributeValue: attributeValue});
            }else{
                attributes.push({attributeName: key, attributeValue: eventData[key]});
            }
        }

        return attributes;
    }
})